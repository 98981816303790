<template>
<div>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-left">
          <div class="flex justify-start">
            <BackButton @click="gotToPreviousPage"></BackButton>
          </div>
        </template>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl">Purchase Event Log Detail</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <div class="grid grid-cols-1 mb-8">
        <div class="m-auto" v-if="errorFetching || isLoading">
          <LoadingCard v-if="isLoading" />
          <ErrorCard v-if="errorFetching" message="CC Details not Available" class="error-card"/>
        </div>
        <div v-else>
          <div class="w-full bg-white p-3 border-none">
                <BasicTable :columns="tableOneColumns" :rows="tableOneRows" th-class="make-columns-even-thirds" table-class="vgt-table bordered clean-borderless-table" />
                <BasicTable :columns="tableTwoColumns" :rows="tableTwoRows" th-class="make-columns-even-thirds" table-class="vgt-table bordered clean-borderless-table" />
                <TitleValueCard
                    title="Error Code:"
                    :value="getErrorCode"
                    class="border-b border-gray-200"
                />
                <TitleValueCard
                    title="Error Message:"
                    :value="getErrorMessage"
                />
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</div>
</template>

<script>
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import BasicTable from "../../components/Helpers/table/BasicTable";
import TitleValueCard from "../../components/Helpers/Cards/TitleValueCard";
import { mapActions, mapState } from "vuex";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import currency from "currency.js";
import BackButton from "../../components/Helpers/Buttons/BackButton";
import LocalLogFormatters from "../../mixin/LocalLogFormatters";
export default {
  name: "VerifoneDetails.vue",
  mixins: [LocalLogFormatters],
  components: {
    BackButton,
    LoadingCard,
    TitleValueCard,
    BasicTable,
    ErrorCard,
    BasicNav,
    PageTemplate,
  },
  data() {
    return {
      errorFetching: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapState("TransactionLogs", ["detailCCTransaction"]),
    tableOneColumns() {
      return [
        {
          label: "Event",
          field: "event",
          type: String,
          formatFn: this.formatLogType,
          thClass: "make-columns-even-thirds",
          tdClass: "credit-detail-event-table-event-data",
        },
        {
          label: "Date/Time",
          field: "createDateTime",
          type: String,
          formatFn: this.formatLogDate,
          thClass: "make-columns-even-thirds",
          tdClass: "credit-detail-event-table-dateTime-data",
        },
        {
          label: "Confirmation",
          field: "confirmationNumber",
          type: String,
          thClass: "make-columns-even-thirds",
          tdClass: "credit-detail-event-table-confirmation-data",
        },
      ];
    },
    tableOneRows() {
      return [
        {
          event: this.detailCCTransaction["event"],
          createDateTime: this.detailCCTransaction["createDateTime"],
          confirmationNumber: this.detailCCTransaction["confirmationNumber"],
        },
      ];
    },
    tableTwoColumns() {
      return [
        {
          label: "Transaction Amount",
          field: "transactionAmount",
          type: String,
          formatFn: this.transactionFormat,
          thClass: "make-columns-even-thirds",
          tdClass: "credit-detail-transaction-table-transactionAmount-data",
        },
        {
          label: "Payment Method",
          field: "cardType",
          type: String,
          formatFn: this.paymentFormat,
          thClass: "make-columns-even-thirds",
          tdClass: "credit-detail-transaction-table-cardType-data",
        },
        {
          label: "Printed",
          field: "printed",
          type: String,
          formatFn: this.formatPrintStatus,
          thClass: "make-columns-even-thirds",
          tdClass: "credit-detail-transaction-table-printed-data",
        },
      ];
    },
    tableTwoRows() {
      return [
        {
          transactionAmount: this.detailCCTransaction["purchaseCost"],
          cardType: this.detailCCTransaction["cardType"],
          printed: {
            total: this.detailCCTransaction["printTotalCount"],
            success: this.detailCCTransaction["printSuccessCount"],
          },
        },
      ];
    },
    getErrorCode() {
      return this.detailCCTransaction.statusCode || "None";
    },
    getErrorMessage() {
      return this.detailCCTransaction.description || "None";
    },
  },
  methods: {
    ...mapActions("TransactionLogs", ["getTransactionLogCCDetails"]),
    async fetchData() {
      this.isLoading = true;
      let id = this.$route.params.id;
      try {
        await this.getTransactionLogCCDetails(id);
      } catch (e) {
        console.log(e);
        this.errorFetching = true;
      } finally {
        this.isLoading = false;
      }
    },
    gotToPreviousPage() {
      this.$router.back();
    },
    transactionFormat(value) {
      return currency(value).format();
    },
    paymentFormat(value) {
      return value ? (this.capitalizeWords(value) + " " + this.detailCCTransaction.lastFour) : "N/A";
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style >
.make-columns-even-thirds {
  width: 33% !important;
  background-color: white !important;
}
.clean-borderless-table{
  border:none !important;
  border-radius: 0px !important;
}
.clean-borderless-table th:first-child {
  border-left: none !important;
}
.clean-borderless-table th:last-child {
  border-right: none !important;
}
.clean-borderless-table td:first-child {
  border-left: none !important;
}
.clean-borderless-table td:last-child {
  border-right: none !important;
}
</style>
